import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Sections from "../components/Sections";
import IosArrowRoundBack from "react-ionicons/lib/IosArrowRoundBack";

const Product = ({ data }) => {
  const { markdownRemark: post } = data;

  const { title, language, sections } = post.frontmatter;

  return (
    <Layout language={language}>
      <SEO
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title={title}
      />
      <div
        className="pt-5"
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <div className="container">
          {language === "en" ? (
            <Link
              to="/products"
              className="btn btn-tertiary"
              style={{ marginLeft: -45 }}
            >
              <IosArrowRoundBack
                className="inline"
                color="#EF4C23"
                fontSize="30px"
              />{" "}
              Back to products
            </Link>
          ) : (
            <Link
              to="/ja/products"
              className="btn btn-tertiary"
              style={{ marginLeft: -45 }}
            >
              <IosArrowRoundBack
                className="inline"
                color="#EF4C23"
                fontSize="30px"
              />{" "}
              Back to products
            </Link>
          )}
        </div>
      </div>
      <Sections sections={sections} language={language} />
    </Layout>
  );
};

Product.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Product;

export const pageQuery = graphql`
  query ProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        language
        date(formatString: "MMMM DD, YYYY")
        title
        description
        sections {
          type
          cssClass
          title
          body
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
          }
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttons {
            text
            url
            type
            modalContent
          }
          solutions {
            icon {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
      }
    }
  }
`;
